<template>
  <card-wrapper class="avg-page">
    <h1>General Data Protection Regulation</h1>
    <slot />
  </card-wrapper>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.avg-page {
  border-radius: 15px;
  box-sizing: border-box;
  form input[type=submit] {
    width: 100%;
    margin-top: 20px;
  }
  .text-box{
    h2{
      font-weight: bold;
    }
  }
}
.darkmode {
  .avg-page {
    background-color: #141619;
      box-shadow: 0 8px 24px #000000;
  }
}
.lightmode {
  .avg-page {
    background-color: white;
    box-shadow: 0 8px 24px #E5E4E6;
  }
}
</style>
