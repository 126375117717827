<template>
  <card-wrapper class="payment-page">
    <h1>Payment</h1>
    <slot />
  </card-wrapper>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.payment-page {
  position: relative;
  height: 575px;
  .payment-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;    
    .payment-box{
      h2{
        font-weight: bold;
      }
      h5{
        font-weight: bold;
        font-size: 16px;
        span{
          float: right;
        }
      }
      p{
        font-size: 16px;
        color: #788995;
      }
      .custom-form-group{
        #card-element{
          box-shadow: 0 1px 3px 0 #E6EBF1;
          height: 25px;
          margin-bottom: 20px;
        }
        p{
          font-weight: bold;
          font-size: 12px;
        }
        .btn{
          height: 50px;
          font-size: 16px;
          width: 100%;  
        }  
      }      
      .error{
        color:red;
      }
    }
    .price-box{
      text-align: center; 
      margin: 0 20px 0 100px; 
      .price-container{
        box-shadow:0 2px 6px 0px #0000000f;
        .price-top{
          background-color: #0170FD;
          height: 74px;
          border-radius: 10px 10px 0 0;
          p{
            color: white;
            font-weight: bold; 
            height: 100%; 
            line-height: 74px; 
            font-size: 20px; 
          }
        }
        .price-bottom{
          text-align: left;
          padding: 15px; 
          background-color: white; 
          height: 155px; 
          border-radius: 0 0 10px 10px;
          ul{
            li{
              &.line{
                height: 1px; 
                width: 100%; 
                background-color: #888787; 
                margin:auto; 
              }
              p{
                margin-top: 12px;
                margin-bottom: 12px;
                font-size: 14px;
                span{
                  float:right;
                }
              }
            }
          }
        }
      }
    }
  }
}
.darkmode {
  .payment-page {
    background-color: #141619;
    .payment-box{
      p{
        color: white;
      }
      .custom-form-group{
        #card-element{
          box-shadow: 0 1px 3px 0 black;
        }
      }

    }
    .price-box{
      .price-container{
        box-shadow: 0 2px 6px 0px black;
        .price-bottom{
          background-color:#1d1f22;
        }
      }
    }      
  }
}

.lightmode {
  .payment-page {
    background-color: white;
  }
}

@media (max-width: 768px) {
  .payment-page{
    .payment-boxes {
      grid-template-columns: auto;
      .payment-box{
        margin: 15px 0;
      }
      .price-box{
        grid-row: 1;
      }
    }
  }
}

</style>
