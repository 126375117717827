<template>
  <card-wrapper class="website-new-page">
    <h1>Edit website</h1>
    <slot />
  </card-wrapper>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
      sites: []
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.website-new-page {
  form input[type=submit] {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
