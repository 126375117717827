<template>
  <a v-if="active_site" :href="`/websites/${id}`" class="site" :class="{'site-down': !online}">
    <h1>{{name}}</h1>
    <a :href="url" target="_blank">{{url}}<img class="dark-only" :src="LinkIconDark"/> <img class="light-only" :src="LinkIconLight"/></a>
    <div v-if="online">Status: Online</div>
    <div v-else >Status: Offline</div>
    <div class="ssl_mark_wrapper">      
      <div v-if="ssl && days_left > 5" class="ssl_mark">SSL Certificaat: <img :src="Check"/> </div>
      <div v-else-if=" ssl && days_left < 5" class="ssl_mark">SSL Certificaat: <img :src="OrangeCheck"/></div>
      <div v-else class="ssl_mark">SSL Certificaat: <img :src="X"/> </div>
      <div class="hidden ssl_information">
        <span>Start date: {{ssl_start_date}}</span>
        <span>Expire date: {{ssl_expire_date}}</span>
        <span>Issuer: {{ssl_issuer}}</span>
      </div>
    </div>

    <div v-if="indexed" class="indexed">Indexed: <img :src="Check"/> </div>
    <div v-else class="indexed">Indexed: <img :src="X"/> </div>

    <status-icon :online="online "></status-icon>
    <div class="dropdown-site dropdown show">
      <a href="#" role="button" class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img class="dark-only" :src="MenuIconDark" /> <img class="light-only" :src="MenuIconLight" /></a>
      <div class="dropdown-site-content dropdown-menu" aria-labelledby="dropdownMenuLink" >
        <ul>
          <a :href="`/websites/${id}`" ><li>Show</li></a>
          <a :href="`/websites/${id}/edit`"><li>Edit</li></a>
          <a :href="`/websites/${id}`" data-confirm="Are you sure?" data-method="delete" ><li>Delete</li></a>
        </ul>
      </div>
    </div>
  </a>

  <a v-else :href="`#`" class="site disabled_site">
    <h1>{{name}}</h1>
    <a :href="url" target="_blank">{{url}}<img class="dark-only" :src="LinkIconDark"/> <img class="light-only" :src="LinkIconLight"/></a>
  </a>





</template>

<script>

import LinkIconDark from '../assets/link-icon-dark.svg'
import LinkIconLight from '../assets/link-icon-light.svg'
import MenuIconDark from '../assets/menu-icon-dark.svg'
import MenuIconLight from '../assets/menu-icon-light.svg'
import Check from '../assets/check.svg'
import X from '../assets/x.svg'
import OrangeCheck from '../assets/orange_check.svg'

export default {
  props: {
    id: Number,
    name: String,
    url: String,
    ssl: Boolean,
    ssl_start_date: String,
    ssl_expire_date: String,
    ssl_issuer: String,
    online: {
      type: Boolean,
      default: true
    },
    days_left: String,
    active_site: Boolean,
    indexed: Boolean
  },
  data: () => {
    return {
      LinkIconDark,
      LinkIconLight,
      MenuIconDark,
      MenuIconLight,
      Check,
      X,
      OrangeCheck
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.site {
  position: relative;
  padding: 25px 20px;
  display: block;
  h1 {
    font-size: 24px;
    margin: 0 0 5px;
  }
  a {
    font-weight: lighter;
    font-size: 14px;
    margin: 0;
    color: black;
    img{
      margin-left: 10px;
    }
  }
  .indexed{
    img{
      width: 16px;
    }
  }
  .ssl_mark_wrapper{
    position: relative;
    .ssl_mark{
      img{
        width: 16px;
      }
    }
    &:hover{
      .ssl_information{
        position: absolute;
        background: #141619;
        padding: 10px;
        display: block;
        z-index: 2;
        border: gray solid 1px;
        span{
          display: block;
        }
      }
    }  
  }
  .status-icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  &.site-down {
    h1 {
      color: #FF3636;
    }
  }
  .dropdown-site{
    position: relative;
    display: inline-block;
    float: right;
    .dropdown-toggle{
      padding: 10px 8px 10px 10px;
      img{
        margin: 0;
      }
      &:after{
        display:none;
      }
    }
    .dropdown-site-content {
      left: -100px !important;
      display: none;
      position: absolute;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      border-radius: 5px;
      &.show {
        display:block;
      }
      ul{
        padding: 10px;
        li{
          padding: 10px;
        }
      }
    }
  }
}

.disabled_site{
  opacity: 0.5;
  cursor: not-allowed;
}


.darkmode {
  .site {
    background-color: #141619; 
    color: white;
    &.selected {
      background-color: rgba(0, 32, 73, 1);
    }
    a{
      color: white;

    }
    .dropdown-site{
      .dropdown-site-content {
        background-color: #141619 ;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      }
    }
  }
}

.lightmode {
  .site {
    background-color: white;
    color: #25252A;
    &.selected {
      background-color: #E7E8FF;
    }
    .ssl_mark_wrapper{
      &:hover{
        .ssl_information{
          background: white;
          border: gray solid 1px;
        }
      }  
    }
  }
}
</style>
