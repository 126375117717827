<template>
  <div class="status-icon" :class="{'offline': !online, animate: animate}">
    <span/>
    <span/>
    <span/>
  </div>
</template>

<script>
export default {
  props: {
    online: {
      type: Boolean,
      default: true
    },
    animate: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes alert {
  0%  {
    background-color: #FF3636;
    transform: translateY(0) scale(1);
  }
  25%  {
    transform: translateY(-2px) scale(1.1);
  }
  50%  {
    background-color: orange;
    transform: translateY(0) scale(1.2);
  }
  75%  {
    transform: translateY(2px) scale(1.1);
  }
  100% {
    background-color: #FF3636;
    transform: translateY(0) scale(1);
  }
}
.status-icon {
  width: 25px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
  background-color: transparent;
  &.offline.animate {
    span {
      animation: alert 1s infinite linear;
      &:nth-child(1) {
        animation-delay: 0.2s;
      }
      &:nth-child(2) {
        animation-delay: 0.1s;
      }
      &:nth-child(3) {
        animation-delay: 0s;
      }
    }
  }
  span {
    height: 6px;
    width: 100%;
    background-color: #07CE72;
    display: block;
    border-radius: 2.5px;
    &:nth-child(2) {
      opacity: 0.6;
    }
    &:nth-child(3) {
      opacity: 0.3;
    }
  }
  &.offline {
    span {
      background-color: #FF3636;
    }
  }
}
</style>