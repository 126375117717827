<template>
  <card-wrapper class="signup-page">
    <h1>Sign up</h1>
    <slot />
  </card-wrapper>
</template>

<script>

export default {
  components: {
  },
  data: () => {
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.signup-page {
  form input[type=submit] {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
