<template>
  <div class="subscription-card" :class="currentPlan">
    <div class="subscription-wrapper">
      <div class="top-part">
        <h3>{{plan}}</h3>
        <h5>€{{price}} <span>/month</span></h5>
      </div>
      <ul class="bottom-part">
        <li class="text-row">Website checks <span>{{checks}}</span></li>
        <li class="text-row">Check frequentie <span>{{frequentie}}</span></li>
        <li class="text-row">Meerdere alert e-mails 
          <span v-if="extraEmails == 0">
            <img class="light-only" :src="minusIcon"/> 
            <img class="dark-only" :src="minusLightIcon"/> 
          </span>
          <span v-else>
            {{extraEmails}}
          </span>
        </li>
        <li class="text-row">Onbeperkt SMS alerts 
          <span>
            <img class="light-only" :src="sms ? checkIcon : minusIcon"/> 
            <img class="dark-only" :src="sms ? checkIcon : minusLightIcon"/> 
          </span>
        </li>
        
      </ul>
        <button v-if="plan == 'Free' " class="btn btn-primary" :disabled="disabled">Current plan</button>
        <a v-else-if="disabled && plan != 'Free'" class="btn btn-primary" :href="`/payment/unsubscribe`" >Cancel subscription</a>
        <a v-else class="btn btn-primary" :href="`/payment/information?plan=${plan.toLowerCase().trim()}`"  >Upgrade to {{plan}}</a>
    </div>
  </div>
</template>

<script>

import checkIcon from '../assets/check.svg'
import minusIcon from '../assets/minus.svg'
import minusLightIcon from '../assets/minus_light.svg'


export default {
  props: {
    plan: String,
    price: Number,
    checks: Number,
    frequentie: Number,
    extraEmails: Number,
    sms: Boolean,
    btnText: String,
    currentPlan: String,
    disabled: Boolean,
  },
  data: () => {
    return {
      checkIcon,
      minusIcon,
      minusLightIcon,
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
.subscription-card {
  text-align: left;
  display: inline-block;
  padding: 15px;
  border-radius: 10px;
  margin: 0px 1%;
  width: 100%;
  max-width: 242px;
  &.current_plan{
    border: 1px solid;
    border-color: #0170FD;
    .btn{
      cursor: not-allowed;
    }
  }
  .subscription-wrapper{
    .btn{
      width: 100%;
      margin: 15px 0px;
    }
    .top-part{
      text-align: center;
      h3{
        font-weight: bold;
      }
      h5{
        span{
          font-size: 11px;
          vertical-align: -5px;
        }
      }
    }
    .bottom-part{
      .text-row{
        padding: 10px 0;        
        span{
          font-weight:bold;
          float: right;
        }
      }
    }
  }
}
.darkmode {
  .subscription-card {
    background-color: #141619;
    box-shadow: 0 8px 24px #000000;
    table{
      a{              
        color: white;
      }
      tbody{
        tr{
          color: white;
        }
      }
    }
  }
}
.lightmode {
  .subscription-card {
    background-color: white;
    box-shadow: 0 8px 24px #E5E4E6;
    table{
      a{              
        color: #25252A;
      }
      th{
        color: #25252A;
      }
    }
  }
}


@media (max-width: 768px) {
  .subscription-card {
    margin: 10px 0;
  }
}


</style>
