<template>
  <div class="page-container" :class="{darkmode: darkmode, lightmode: !darkmode}">
    <slot />
  </div>
</template>

<script>
import LocalStore from "./local-store";

export default {
  components: {
  },
  data: () => {
    return {
      darkmode: LocalStore("darkmode")
    }
  },
  methods: {
    setDarkmode (mode) {
      LocalStore('darkmode', mode)
      document.getElementsByTagName('html')[0].classList.remove('darkmode')
      document.getElementsByTagName('html')[0].classList.remove('lightmode')
      document.getElementsByTagName('html')[0].classList.add(mode ? 'darkmode' : 'lightmode')
      this.darkmode = LocalStore("darkmode")
    }
  },
  mounted () {
    const darkmodeElem = $(".darkmodeSwitch:checkbox")
    darkmodeElem.prop('checked', this.darkmode)
    darkmodeElem.change((e) => {
      this.setDarkmode(e.target.checked)
    })
  },
  created () {
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700;900&display=swap');
body {
  font-family: 'Raleway', sans-serif;
  overflow: hidden;
  .page-container {
    height: 100vh;
    overflow-y: scroll;
    &.lightmode {
      background-color: #F2F3FA;
      color: #25252A;
      .dark-only {
        display: none;
      }
      .dashboard-button, .spectate-button {
        border-color: #25252A;
        color: #25252A;
        img {
            display: none;
          &.dark-icon {
            display: inline-block;
          }
        }
      }
    }
    &.darkmode {
      background-color: #0B0C0E;
      color: white;
      .light-only {
        display: none;
      }
      .custom-switch-indicator {
        background-color: #25252A;
        &::before {
          background-color: #25252A;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjY0cHgiIGhlaWdodD0iMjY0cHgiIHZpZXdCb3g9IjAgMCAyNjQgMjY0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2NCAoOTM1MzcpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ibW9vbiIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTIyOC4yOTc4MzgsMjI0LjgzMzgwNCBDMjQ2LjQzNTQ3NiwyMDYuNjg5NjQ2IDI1OC4yNjc1ODksMTg0LjYyODU3IDI2My43OTk2NjksMTYxLjM3MjM0NiBDMjY1LjM4Mzg1NywxNTQuNzEwODExIDI1Ny4xOTk4MDEsMTUwLjIxMzM4NSAyNTIuMzcxNzcyLDE1NS4wNjcxNTcgQzI1Mi4zMTI0OTksMTU1LjEyNzAzIDI1Mi4yNTMyMjUsMTU1LjE4NjMyNSAyNTIuMTkzOTUyLDE1NS4yNDU2MTkgQzIxMS44Mzg5MzYsMTk1LjYxNTE0MyAxNDUuOTI2NzA3LDE5NC44MzY3OTMgMTA2LjU1NzA3MywxNTIuOTEwODU3IEM2OS40NDA0MDQ4LDExMy4zODM2MDYgNjkuOTI3NjAyNiw1Mi4wMTgyODUyIDEwNy42MTkwNzgsMTMuMDM5NDM3NSBDMTA4LjA5NDQyMSwxMi41NDgwMTUyIDEwOC41NzMyMzQsMTIuMDYyMzc3NiAxMDkuMDU2MDk1LDExLjU4MjUyNDkgQzExMy44OTc0MjQsNi43NzA5ODIxMyAxMDkuMzcyNDEyLC0xLjM3ODcwNDA4IDEwMi43MzI5MzIsMC4xOTk2OTAyMTEgQzc5LjY5MTc5OSw1LjY3NzM1NzY0IDU3LjgyMjI1NDMsMTcuMzQ0ODA2NiAzOS43NzMwOTI0LDM1LjIwODExMTIgQy0xMi45OTk4MzA0LDg3LjQzODI5NzggLTEzLjI5NzA2NDQsMTcyLjExOTQyNyAzOS4xMjM5NzcyLDIyNC43MDIxOTkgQzkxLjMxNTczMjMsMjc3LjA1NTYwMyAxNzYuMDUxMTQ2LDI3Ny4wOTg5ODkgMjI4LjI5NzgzOCwyMjQuODMzODA0IFoiIGlkPSJQYXRoIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
        }
      }
      .custom-form-group {
        label {
          color: white;
        }
        select{
          color: white;
          &::placeholder {
            color: #74767B;
          }
          option{
            background-color: #141619;
          }
        }
        textarea{
          color: white;
          &::placeholder {
            color: #74767B;
          }
        }
        input {
          color: white;
          &::placeholder {
            color: #74767B;
          }
        }
      }
      .dropdown-menu-arrow:before {
        background-color: #141619;
        border-color: #141619;
      }
      .header .dropdown-menu {
        background: #141619;
        color: white;
        box-shadow: 0 8px 24px #000000;
        .dropdown-item {
          color: white;
          &:hover {
            background: rgba(255,255,255, .1);;
          }
        }
        .dropdown-divider {
          background-color: #0B0C0E;
          border-color: rgba(255,255,255, .1);
        }
      }
    }
  }
}

h1 {
  font-weight: 900;
  font-size: 34px;
  margin: 0;
}

.custom-form-group {
  .field_with_errors{
    input{
      border-color: red !important;
    }
  }
  label {
    display: block;
    margin-top: 20px;
    font-weight: 700;
    font-size: 16px;
    color: #333;
  }
  select{
    color: #161616;
    resize: none;
    width: 100%;
    border: none;
    padding: 5px 20px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid rgba(123, 123, 123, 0.4);
    border-radius: 10px;
    font-size: 16px;
    outline: none;
    height: 40px;
  }
  textarea{
    color: #161616;
    resize: none;
    width: 100%;
    border: none;
    padding: 5px 20px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid rgba(123, 123, 123, 0.4);
    border-radius: 10px;
    font-size: 16px;
    outline: none;
  }
  input:not([type=checkbox]) {
    color: #161616;
    resize: none;
    width: 100%;
    border: none;
    padding: 5px 20px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #74767B;
    border-radius: 10px;
    font-size: 16px;
    outline: none;
    height: 40px;
  }
  &.group-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }
}

.btn {
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
}
.btn-primary {
  background-color: #0170FD;
}

.btn-danger {
  background-color: #fd0101;
}


.header {
  .container{
    .d-flex{
      position: relative;
      .add-website {
        height: 35px;
        padding: 5px 20px;
        margin: 5px 20px;
      }
    }
  }
}

.mobile-header{
  .add-website {
    height: 35px;
    padding: 5px 20px;
    margin: 5px 20px;
  }
}

.dashboard-button, .spectate-button {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  border: 1px solid white;
  width: fit-content;
  padding: 7px 15px;
  border-radius: 20px;
  margin: 5px auto;
  font-weight: bold;
  color: white;
  &:hover {
    color: white;
  }
  img {
    margin-right: 5px;
    &.dark-icon {
      display: none;
    }
  }
}

.custom-switch-input:focus ~ .custom-switch-indicator {
  border-color: #0170FD;
}

.header .dropdown-menu {
  box-shadow: 0 8px 24px #E5E4E6;
}


@media (max-width: 768px) {
  .custom-form-group {
    &.group-2{
      grid-template-columns: auto;
    }
  }
}

</style>
