<template>
  <card-wrapper class="extra-email-page">
    <h1>Alert Settings</h1>
    <div class="extra-email-boxes">
      <div class="extra-email-box">
        <h2>Extra e-mail</h2>
        <p>You can add extra e-mail addresses to send alerts to. When you want to inform your colleagues or clients as well when something goes wrong for example</p>
        </br>
        <p>When the e-mail address is confirmed you can go to the website settings to define who shoud be informed when something goes wrong </p>     

        <SortedTable :values="extraEmails">
          <thead>
            <tr>
              <th scope="col">
                <SortLink name="name">E-mail</SortLink>
              </th>
              <th scope="col">
                <SortLink name="age">Confirmed</SortLink>
              </th>
              <th scope="col">
              </th>
            </tr>
          </thead>
          <tbody slot="body" slot-scope="sort">
            <tr v-for="extraEmail in extraEmails" :key="extraEmails.id">
              <td>{{ extraEmail.email }}</td>
              <td>
                <img class="light-only" :src="extraEmail.confirmed ? checkIcon : minusIcon"/> 
                <img class="dark-only" :src="extraEmail.confirmed ? checkIcon : minusLightIcon"/> 
             </td>
              <td><button v-on:click="deleteExtraEmail(extraEmail)"><img :src="deleteIcon"/> </button></td>
            </tr>
          </tbody>
        </SortedTable>
          <p v-for="error in errors" class="error">
            - {{error}}
          </p>        

        <div class="custom-form-group">
          <input v-model="extraEmail" type="email" class="" placeholder="E-mail"></input>
          <button v-on:click="postExtraEmail(extraEmail)" class="btn btn-primary btn-add-extra-email">Add e-mail</button>
        </div>
      </div>

      <div class="extra-email-box settings-box">
        <h2>Settings</h2>
      </div>
    </div>
  </card-wrapper>
</template>

<script>

import checkIcon from '../assets/check.svg'
import minusIcon from '../assets/minus.svg'
import minusLightIcon from '../assets/minus_light.svg'
import deleteIcon from '../assets/delete.svg'

export default {
  components: {
  },
  data: () => {
    return {
      extraEmail: "",
      checkIcon,
      minusIcon,
      minusLightIcon,
      deleteIcon,
      extraEmails: [],
      settings: [],
      errors: [],
      error: "",
    }
  },
  methods: {
    deleteExtraEmail (extraEmail){
      let self = this;
      this.$http.delete(`/extra_emails/${extraEmail.id}.json`).then(function (response) {
        self.msg = response.msg;
        self.extraEmails = self.extraEmails.filter(
          email => email.id != extraEmail.id
        )
      }).catch(function (error) {
        console.log(error);
      })
    },
    fetchExtraEmails (id) {
      let self = this;
      this.$http.get('/extra_emails.json').then(function (response) {
        self.extraEmails = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    postExtraEmail(extraEmail){
      let self = this;
      this.$http.post('/extra_emails.json', {email: extraEmail}).then(function (response) {
        self.extraEmails.push(response.data);
        self.extraEmail = '';
      }).catch(function (error) {
        self.errors = error.response.data;
        console.log(error.response.data);
      })
    }
  },
  created () {
    this.fetchExtraEmails();
  }
}
</script>

<style lang="scss">
.extra-email-page {
  position: relative;
  .extra-email-boxes{
    display: grid;
    grid-template-columns: 60% 40%;
    grid-column-gap: 30px;  
    .extra-email-box{
      h2{
        font-weight: bold;
      }
      .error{
        color:red;
      }
      .table{
        th, td{
          border: none;
        }
        tbody{
          tr{
            &:nth-child(odd){
              background-color: #F7F7F7;
            }
          }  
        }
        a{
          font-weight: bold;
        }
      }
    }
    .right-box{
    }
  }
  .custom-form-group{
    position: relative;  
    input{
      border-radius: 10px 0px 0px 10px;
      display:inline-block;
      width: 70%;
      border-color: #0170FD;
    }
  }
  .btn-add-extra-email{
    position: absolute;
    border-radius: 0px 10px 10px 0px;
    display: inline-block;
    width: 29%;
  }
}
.darkmode {
  .extra-email-page {
    background-color: #141619;
    box-shadow: 0 8px 24px #000000;
    .extra-email-boxes{
      .extra-email-box{
        table{
          tbody{
            tr{
              color: white;
              &:nth-child(odd){
                background-color: #1C1F23;
              }
            }
          }
          a{
            color: white;
          }
        }            
      } 
    }
  }
}
.lightmode {
  .extra-email-page {
    background-color: white;
    box-shadow: 0 8px 24px #E5E4E6;
    table{
      a{              
        color: #25252A;
      }
      th{
        color: #25252A;
      }
    }
  }
}

@media (max-width: 768px) {
  .extra-email-page{
    .extra-email-boxes {
      grid-template-columns: auto;
      .extra-email-box{
        margin: 15px 0;
      }
      .settings-box{
        grid-row: 1;
      }
    }
  }
}


</style>
