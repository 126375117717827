export default function localStore(key, value) {
  if (value === undefined) {
    let value = window.localStorage.getItem(key);
    if (value !== null) {
      try {
        value = JSON.parse(value);
      } catch (e) {
        localStore(key, value);
      }
    }
    return value;
  } else {
    if (value === null) {
      window.localStorage.removeItem(key);
      return null;
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
      return value;
    }
  }
}
