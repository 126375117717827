<template>
  <div class="dashboard">
    <section class="site-overview">
      <site v-if="sites" v-for="site in sites" :key="site.id" :id="site.id" :name="site.name" :url="site.url" :online="site.online && site.online_app" :ssl="site.ssl" :ssl_start_date="site.ssl_start_date" :ssl_expire_date="site.ssl_expire_date" :ssl_issuer="site.ssl_issuer" :days_left="( (new Date(site.ssl_expire_date) - new Date() ) / (1000*3600*24) ).toFixed(0)" :active_site="site.active" :indexed="site.indexed" />
    </section>
  </div>
</template>

<script>
import Site from '../components/Site'

export default {
  components: {
    Site
  },
  data: () => {
    return {
      sites: []
    }
  },
  methods: {
    fetchSites () {
      let self = this;
      this.$http.get('/pages/index.json').then(function (response) {
        self.sites = response.data
      }).catch(function (error) {
        console.log(error);
      })
    }
  },
  created () {
    this.fetchSites();
    setInterval(() => { this.fetchSites(); }, 10 * 1000);
  }
}
</script>

<style lang="scss">
.dashboard {
  .center{
    margin-top: 20px;
    text-align: center;
  }
  .site-overview {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    max-width: 1130px;
    margin: 0 auto;
    padding: 40px 15px;
    .site {
      border-radius: 10px;
    }

  }
}
.darkmode {
  .dashboard {
    .site-overview {
      .site {
        box-shadow: 0 8px 24px #000000;
      }
    }
  }
}
.lightmode {
  .dashboard {
    .site-overview {
      .site {
        box-shadow: 0 8px 24px #E5E4E6; 
      }
    }
  }
}

@media (max-width: 960px) {
  .dashboard{
    .site-overview{
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: 768px) {
  .dashboard{
    .site-overview{
      grid-template-columns: 1fr;
    }
  }
}

.unsubscribed{
  .unselected{
    opacity: 0.5;
  }  
}


</style>
