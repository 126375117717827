<template>
  <div class="detail-card small-card">
    <div class="details-wrapper">
      <div class="text-box">
        <h5 :class="headerClass" >{{header}}</h5>
        <div class="icon">
          <img :src="icon"/>
        </div>
        <p>{{text}}</p>
    
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    icon: String, 
    header: String,
    headerClass: String,
    text: String
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
.detail-card {
  padding: 15px;
  border-radius: 10px;
  &.response-time-chart-card {
    grid-column: 1/4;
  }
  .details-wrapper{
    padding: 10px;
    .icon{
      display: inline-block;
      float: right;
    }
    .text-box{
      h5{
        display: inline-block;
        font-size: 25px;
        font-weight: bold;
      }
      .green{
        color: #07ce72;
      }
      .red{
        color: red;
      }
    }
  }
}


</style>
