<template>
  <card-wrapper class="users-overview-page">
    <h1>Alert Settings</h1>
    <div class="users-table">  
      <SortedTable :values="extraEmails">
        <thead>
          <tr>
            <th scope="col">
              <SortLink name="name">E-mail</SortLink>
            </th>
            <th scope="col">
              <SortLink name="age">Confirmed</SortLink>
            </th>
            <th scope="col">
            </th>
          </tr>
        </thead>
        <tbody slot="body" slot-scope="sort">
          <tr v-for="extraEmail in extraEmails" :key="extraEmails.id">
            <td>{{ extraEmail.email }}</td>
            <td>
              <img class="light-only" :src="extraEmail.confirmed ? checkIcon : minusIcon"/> 
              <img class="dark-only" :src="extraEmail.confirmed ? checkIcon : minusLightIcon"/> 
           </td>
            <td><button v-on:click="deleteExtraEmail(extraEmail)"><img :src="deleteIcon"/> </button></td>
          </tr>
        </tbody>
      </SortedTable>
    </div>
  </card-wrapper>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.users-overview-page {
  position: relative;
  .users-table{
    h2{
      font-weight: bold;
    }
  }
}
.darkmode {
  .users-overview-page {
    background-color: #141619;
    box-shadow: 0 8px 24px #000000;
    table{
      a{              
        color: white;
      }
      tbody{
        tr{
          color: white;
        }
      }
    }
  }
}
.lightmode {
  .users-overview-page {
    background-color: white;
    box-shadow: 0 8px 24px #E5E4E6;
    table{
      a{              
        color: #25252A;
      }
      th{
        color: #25252A;
      }
    }
  }
}

</style>
