/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

import axios from 'axios'
import Vue from 'vue/dist/vue.esm'
import App from './App'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import SortedTablePlugin from "vue-sorted-table";

Vue.component('app', App)
Vue.use(Chartkick.use(Chart))
Vue.use(SortedTablePlugin);

// Components
import Site from './components/Site'
import SiteMini from './components/SiteMini'
import StatusIcon from './components/StatusIcon'
import CardWrapper from './components/CardWrapper'
import DetailCard from './components/DetailCard'
import SubscriptionCard from './components/SubscriptionCard'
Vue.component('site', Site)
Vue.component('site-mini', SiteMini)
Vue.component('status-icon', StatusIcon)
Vue.component('card-wrapper', CardWrapper)
Vue.component('detail-card', DetailCard)
Vue.component('subscription-card', SubscriptionCard)

// Views
import Dashboard from './views/Dashboard'
import ProfilePage from './views/ProfilePage'
import AvgPage from './views/AvgPage'
import ExtraEmail from './views/ExtraEmailPage'
import Settings from './views/Settings'
import WebsiteDetail from './views/website/WebsiteDetail'
import WebsiteNew from './views/website/WebsiteNew'
import WebsiteEdit from './views/website/WebsiteEdit'
import Login from './views/Login'
import Signup from './views/Signup'
import ForgotPassword from './views/ForgotPassword'
import ResendInformation from './views/ResendInformation'
import SubscriptionPage from './views/SubscriptionPage'
import Support from './views/Support'
import Overviews from './views/Overviews'
import PaymentPage from './views/payment/PaymentPage'
import InformationPage from './views/payment/InformationPage'
import AfterPaymentPage from './views/payment/AfterPaymentPage'
import UnsubscribePage from './views/payment/UnsubscribePage'
import UsersOverview from './views/UsersOverview'
import ActiveSitesPage from './views/ActiveSitesPage'
Vue.component('dashboard', Dashboard)
Vue.component('website-detail', WebsiteDetail)
Vue.component('website-new', WebsiteNew)
Vue.component('website-edit', WebsiteEdit)
Vue.component('profile-page', ProfilePage)
Vue.component('avg-page', AvgPage)
Vue.component('extra-email-page', ExtraEmail)
Vue.component('settings', Settings)
Vue.component('login', Login)
Vue.component('signup', Signup)
Vue.component('forgot-password', ForgotPassword)
Vue.component('resend-information', ResendInformation)
Vue.component('subscription-page', SubscriptionPage)
Vue.component('support', Support)
Vue.component('overviews', Overviews)
Vue.component('payment-page', PaymentPage)
Vue.component('information-page', InformationPage)
Vue.component('after-payment-page', AfterPaymentPage)
Vue.component('unsubscribe-page', UnsubscribePage)
Vue.component('users-overview', UsersOverview)
Vue.component('active-sites-page', ActiveSitesPage)


Vue.prototype.$http = axios


document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '[data-behavior="vue"]'
  })
})