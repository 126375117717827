<template>
  <div class="site" :class="{active: active} " >
    <h1>{{name}}</h1>
    <a target="_blank">{{url}}</a>
  </div>
</template>

<script>

export default {
  props: {
    name: String,
    url: String,
    active: Boolean
  },
  data: () => {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.site {
  position: relative;
  padding: 25px 20px;
  display: block;
  h1 {
    font-size: 24px;
    margin: 0 0 5px;
    text-align: left;
  }
  a {
    font-weight: lighter;
    font-size: 14px;
    margin: 0;
    color: black;
    img{
      margin-left: 10px;
    }
  }
}

.darkmode {
  .site {
    background-color: #141619; 
    border: 1px solid #74767B;
    color: white;
    &.selected {
      background-color: rgba(0, 32, 73, 1);
    }
    a{
      color: white;

    }
  }

}

.lightmode {
  .site {
    background-color: white;
    border: 1px solid #74767B;
    color: #25252A;
    &.selected {
      background-color: #E7E8FF;
    }
  }
}
</style>
