<template>
  <card-wrapper class="after-payment-page">
    <h1>Succeeded!</h1>
    <slot />
  </card-wrapper>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.after-payment-page {
  position: relative;
}
.darkmode {
  .after-payment-page {
    background-color: #141619;
    box-shadow: 0 8px 24px #000000;
  }
}
.lightmode {
  .after-payment-page {
    background-color: white;
    box-shadow: 0 8px 24px #E5E4E6;
  }
}
</style>
