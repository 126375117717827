<template>
  <card-wrapper class="support-page">
    <h1>Support</h1>
    <div class="text-box">
      <h2>Get in touch</h2>
      <p>Do you have any problems, questions, feedback or feature requests. We would love to help you. Submit your support request and we will answer you as quickly as possible.</p>
    </div>
    <slot />
  </card-wrapper>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.support-page {
  width: 575px;
  .text-box{
    h2{
      font-weight: bold;
    }
  }
  form input[type=submit] {
    width: 100%;
    margin-top: 20px;
  }
}
.darkmode {
  .support-page {
    background-color: #141619;
      box-shadow: 0 8px 24px #000000;
  }
}
.lightmode {
  .support-page {
    background-color: white;
    box-shadow: 0 8px 24px #E5E4E6;
  }
}


@media (max-width: 768px) {
  .support-page {
    width: 100%
  }
}


</style>
