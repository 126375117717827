<template>
  <card-wrapper class="unsubscribe-page">
    <h1>Cancel subscription</h1>
    <div class="text-box">
      <h2>We are sad to see you leave :(</h2>
      <p>If you do like to cancel your subscription you will be returned to the "Free" plan and you can choose which website you wanna keep active.</p>
    </div>
    <section class="site-overview">
      <site-mini v-for="site in sites" :name="site.name" :url="site.url" :active="selectedId == site.id" @click.native="selectedId = site.id" />
    </section>
    <form action="/payment/unsubscribe" method="post">
      <input class="unsubscribe_field" type="hidden" :value="selectedId" name="site_id">
      <input type="submit" value="Cancel subscription... :(" class="btn btn-primary">
    </form>
    <slot />
  </card-wrapper>
</template>

<script>

export default {

  data: () => {
    return {
      sites: [],
      selectedId: ""
    }
  },
  methods: {
    fetchSites () {
      let self = this;
      this.$http.get('/pages/index.json').then(function (response) {
        self.sites = response.data
        self.selectedId = response.data[0].id
      }).catch(function (error) {
        console.log(error);
      })
    }
  },
  created () {
    this.fetchSites();
  }
}
</script>

<style lang="scss">
.unsubscribe-page {
  width: 1100px;
  .text-box{
    h2{
      font-weight: bold;
    }
  }
  form input[type=submit] {
    width: 100%;
    margin-top: 20px;
  }
  .site-overview {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    max-width: 1130px;
    margin: 0 auto;
    padding: 40px 15px;
    .site {
      border-radius: 10px;
      opacity: 0.5;
    }
    .active{
      border: 2px solid lightgreen;
      opacity: 1;
    }
  }
}
.darkmode {
  .unsubscribe-page {
    background-color: #141619;
      box-shadow: 0 8px 24px #000000;
  }
}
.lightmode {
  .unsubscribe-page {
    background-color: white;
    box-shadow: 0 8px 24px #E5E4E6;
  }
}


@media (max-width: 768px) {
  .unsubscribe-page {
    width: 100%
  }
}


</style>
