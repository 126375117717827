<template>
  <div class="subscription-page">
    <subscription-card v-for="plan in filteredPlans" :key="plan.plan_number" 
      :plan="plan.name"
      :price="plan.price"
      :checks="plan.checks"
      :frequentie="plan.frequentie"
      :extraEmails="plan.extraEmails"
      :sms="plan.sms"
      :disabled="plan.plan_number == planNumber"
    />
  </div>
</template>
<script>
export default {
  props: {
    planNumber: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {
      plans: [
        {plan_number: 0, name: 'Free', price: 0.00, checks: 3, frequentie: 5, extraEmails: 0, sms: true},
        {plan_number: 1, name: 'Essential', price: 2.99, checks: 10, frequentie: 1, extraEmails: 0, sms: true},
        {plan_number: 2, name: 'Pro', price: 6.99, checks: 15, frequentie: 1, extraEmails: 5, sms: true},
        {plan_number: 3, name: 'Business', price: 14.99, checks: 30, frequentie: 1, extraEmails: 5, sms: true}
      ]
    }
  },
  computed: {
    filteredPlans() {
      return this.plans.filter(plan => plan.plan_number >= this.planNumber)
    }
  },
  created () {
  }
}
</script>

<style lang="scss">
.subscription-page{
  text-align: center
}
</style>
