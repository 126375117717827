<template>
  <card-wrapper class="active-sites-page">
    <h1>Active websites</h1>
    <div class="text-box">
      <p>You can select the websites you would like to keep active or inactive</p>
      <p> {{selectedSites.length}}/{{sitesAllowed}} </p>
    </div>
    <section class="site-overview">
      <site-mini v-for="site in sites" :key="site.id" :id="site.id" :name="site.name" :url="site.url" :active="selectedSites.includes(site.id)" @click.native="setSelectedSites(site)" />
    </section>
    <form action="/settings/active_sites" method="post">
      <input class="selected_sites_field" type="hidden" :value="selectedSites" name="selected_site_ids[]" multiple="true">
      <input type="submit" value="Set active or inactive" class="btn btn-primary">
    </form>
    <slot />
  </card-wrapper>
</template>

<script>

export default {
   components: {
  },
  data: () => {
    return {
      sites: [],
      sitesAllowed: 1,
      selectedSites: []
    }
  },
  methods: {
    fetchSites () {
      let self = this;
      this.$http.get('/pages/index.json').then(function (response) {
        self.sites = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    fetchSelectedSites (){
      let self = this;
      this.$http.get('/pages/selected_sites.json').then(function (response) {
        self.selectedSites = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    fetchAllowed () {
      let self = this;
      this.$http.get('/user/sites_allowed.json').then(function (response) {
        self.sitesAllowed = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    setSelectedSites (site){
      let self = this;

      if( self.selectedSites.length < self.sitesAllowed && !self.selectedSites.includes(site.id) ) {
        self.selectedSites.push(site.id)
      }else{
        if(self.selectedSites.includes(site.id)){
          var index = self.selectedSites.indexOf(site.id);
          self.selectedSites.splice(index, 1)
        }
      }
    }
  },
  created () {
    this.fetchSites();
    this.fetchAllowed();
    this.fetchSelectedSites();

  }
}
</script>

<style lang="scss">
.active-sites-page {
  width: 1100px;
  .text-box{
    h2{
      font-weight: bold;
    }
  }
  form input[type=submit] {
    width: 100%;
    margin-top: 20px;
  }
  .site-overview {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    max-width: 1130px;
    margin: 0 auto;
    padding: 40px 15px;
    .site {
      border-radius: 10px;
      opacity: 0.5;
    }
    .active{
      border: 2px solid lightgreen;
      opacity: 1;
    }
  }
}
.darkmode {
  .active-sites-page {
    background-color: #141619;
      box-shadow: 0 8px 24px #000000;
  }
}
.lightmode {
  .active-sites-page {
    background-color: white;
    box-shadow: 0 8px 24px #E5E4E6;
  }
}


@media (max-width: 768px) {
  .unsubscribe-page {
    width: 100%
  }
}


</style>
