<template>
  <div class="card-wrapper">
    <slot />
  </div>
</template>

<script>

export default {
  components: {
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.card-wrapper {
  max-width: 960px;
  width: 100%;
  margin: 40px auto;
  border-radius: 15px;
  padding: 40px 60px 20px;
  box-sizing: border-box;
  h1{
    text-align: center;
    margin-bottom: 40px;
  }
}
.darkmode {
  .card-wrapper {
    background-color: #141619;
    box-shadow: 0 8px 24px #000000;
  }
}
.lightmode {
  .card-wrapper {
    background-color: white;
    box-shadow: 0 8px 24px #E5E4E6;
  }
}

@media (max-width: 960px) {
  .card-wrapper{
  }
}

@media (max-width: 768px) {
  .card-wrapper{
    padding: 40px 30px 20px;

  }
}
</style>
