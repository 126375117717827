<template>
  <card-wrapper class="information-page">
    <h1>Payment Information</h1>
    <slot />
  </card-wrapper>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.information-page {
  position: relative;
  form input[type=submit] {
    width: 100%;
    margin-top: 20px;
  }
}
.darkmode {
  .information-page {
    background-color: #141619;
  }
}

.lightmode {
  .information-page-page {
    background-color: white;
  }
}

</style>
