<template>
  <div class="website-detail">
    <section class="website-list-view">
      <div class="button-row">
        <a class="btn btn-close">X</a>
      </div>
      <site v-for="site in sites"
            :key="site.id" :id="site.id"
            :name="site.name"
            :url="site.url"
            :online="site.online && site.online_app"
            :ssl="site.ssl"
            :ssl_start_date="site.ssl_start_date"
            :ssl_expire_date="site.ssl_expire_date"
            :ssl_issuer="site.ssl_issuer"
            :days_left="( (new Date(site.ssl_expire_date) - new Date() ) / (1000*3600*24) ).toFixed(0)"
            :class="{selected: selectedSite && site.id == selectedSite.id}"
            :active_site="site.active"
            :indexed="site.indexed"/>
    </section>
    <section class="website-details-section">
      <div v-if="selectedSite" class="website-detail-view">
        <h1>{{selectedSite.name}}</h1>
        <a :href="selectedSite.url" target="_blank">{{selectedSite.url}}</a>
        <section class="detail-card-wrapper">
          <div class="detail-card response-time-chart-card">
            <h3 class="count mt-0 number-font">{{siteDetail.average_ms}} ms(last 15 pings)</h3>
            <area-chart :data="siteDetail.data" :library="{scales: {xAxes: [{display: false}]}}" height="120px"></area-chart>
          </div>
          
          <detail-card :header="selectedSite.online_app ? 'Up' : 'Down'" :text="'Site up or down?'" :headerClass="selectedSite.online_app ? 'green' : 'red'"></detail-card>
          <detail-card :header="selectedSite.online ? 'Up' : 'Down'" :text="'Server up or down?'" :headerClass="selectedSite.online ? 'green' : 'red'"></detail-card>
          <detail-card :header="siteInformation.avarage_ms" :text="'Average response time (ms)'" :icon="clockIcon"></detail-card>
          <detail-card :header="siteInformation.downtime" :text="'Downtime (min)'" :icon="downIcon"></detail-card>
          <detail-card :header="siteInformation.uptime+'%'" :text="'Uptime'" :icon="upIcon"></detail-card>
          <detail-card :header="siteInformation.outage" :text="'Outages'" :icon="warningIcon"></detail-card>

          <div class="detail-card pings-chart-card">
            <h3 class="count mt-0 number-font">Last 15 pings</h3>
            <SortedTable :values="pings">
              <thead>
                <tr>
                  <th scope="col" class="response">
                    <SortLink name="name">Response</SortLink>
                  </th>
                  <th scope="col">
                    <SortLink name="age">SVR</SortLink>
                  </th>
                  <th scope="col">
                    <SortLink name="age">APP</SortLink>
                  </th>
                  <th scope="col" class="ms">
                    <SortLink name="age">MS</SortLink>
                  </th>
                  <th scope="col">
                    <SortLink name="age">Time</SortLink>
                  </th>
                </tr>
              </thead>
              <tbody slot="body" slot-scope="sort">
                <tr v-for="ping in pings" :key="ping.id">
                  <td class="response">{{ ping.response_code }}</td>
                  <td>{{ ping.online ? 'Online' : 'Offline' }}</td>
                  <td>{{ ping.online_app ? 'Online' : 'Offline' }}</td>
                  <td class="ms">{{ ping.ms }}</td>
                  <td>{{ ping.created_at }}</td>
                </tr>
              </tbody>
            </SortedTable>
          </div>

        </section>
      </div>
    </section>
    <a class="btn btn-menu mobile_button">...</a>
  </div>
</template>

<script>
import Site from '../../components/Site'
import clockIcon from '../../assets/clock.svg'
import warningIcon from '../../assets/warning.svg'
import upIcon from '../../assets/up.svg'
import downIcon from '../../assets/down.svg'

export default {
  components: {
    Site
  },
  data: () => {
    return {
      clockIcon,
      warningIcon,
      upIcon,
      downIcon,
      sites: [],
      siteInformation: {uptime: ""},
      pings: [],
      siteDetail: {
        average_ms: "",
        data: []
      }
    }
  },
  watch: {
    selectedSite(site) {
      this.fetchSiteInformation(site.id)
      this.fetchPings(site.id)
      this.fetchGraphData(site.id)
    }
  },
  computed: {
    selectedSite () {
      return this.sites.find(s => s.id == window.location.pathname.split("/").slice(-1)[0])
    }
  },
  methods: {
    fetchSites () {
      let self = this;
      this.$http.get('/pages/index.json').then(function (response) {
        self.sites = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    fetchSiteInformation (id) {
      let self = this;
      this.$http.get('/website/'+id+'.json').then(function (response) {
        self.siteInformation = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    fetchPings (id) {
      let self = this;
      this.$http.get('/website/'+id+'/pings.json').then(function (response) {
        self.pings = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    fetchGraphData (id) {
      let self = this;
      this.$http.get('/website/'+id+'/graphdata.json').then(function (response) {
        self.siteDetail = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
  },
  created () {
    this.fetchSites();
    setInterval(() => { this.fetchSites(); }, 10 * 1000);
  }
}
</script>

<style lang="scss">
.website-detail {
  display: grid;
  grid-template-columns: 320px 1fr;
  height: calc(100vh - 74px);
  .btn-menu{
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0170FD;
    color: white;
  }
  .website-details-section{
    overflow-y: scroll;
  }
  .website-list-view {
    padding: 5px 0;
    display: grid;
    grid-row-gap: 5px;
    height: 100%;
    overflow-y: scroll;
    transition: 0.5s;
    grid-auto-rows: max-content;
    .button-row{
      display: none;
      position: relative;
      height: 40px;
      .btn-close{
        position: absolute;
        top: 0;
        right: 20px;
      }
    }
    .site{
    }
  }
  .website-detail-view {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 40px 20px;
    h1 {
      margin-bottom: 5px;
    }
    .detail-card-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 15px;
      grid-column-gap: 15px;
      margin-top: 40px;
      .detail-card {
        padding: 15px;
        border-radius: 10px;
        &.response-time-chart-card {
          grid-column: 1/4;
        }
        &.pings-chart-card {
          grid-column: 1/4;
        }
      }
    }
  }
}
.darkmode {
  .website-detail {
    .website-detail-view {
      .detail-card {
        background-color: #141619;
        box-shadow: 0 8px 24px #000000;
      }
      table{
        tr{
          color: white;
        }
      }
    }
  }
  .button-row{
    .btn-close{
      color: white;
    }
  }
}
.lightmode {
  .website-detail {
    .website-detail-view {
      .detail-card {
        background-color: white;
        box-shadow: 0 8px 24px #E5E4E6;
      }
      table{
        tr{
          color: #25252A;
        }
      }
    } 
  }
}

.mobile_button{
  display: none;
}


@media (max-width: 960px) {
  .mobile_button{
    display: block;
  }
  .website-detail{
    display: block;
    .website-list-view{
      display: none;
      width:100%;
      .button-row{
        display: block;
      }
    }
  }
}

@media (max-width: 768px) {
  .website-detail{
    display: block;
    .website-list-view{
      display: none;
      width:100%; 
      .button-row{
        display: block;
      }
    }
    .website-details-section{
      .website-detail-view{  
        .detail-card-wrapper{
          grid-template-columns: 1fr;
          .pings-chart-card{
            grid-column: 1;

          }
          .response-time-chart-card{
            display: none;
          }
          .response{
            display: none;
          }
          .ms{
            display: none;
          }
        }
      }
    }
  }
}
</style>
