<template>
  <div class="settings-page">
    <div class="settings-sub-menu">
      <ul>
        <slot name="menu-items"></slot>
      </ul>
    </div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
      sites: []
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.settings-page {
  display: grid;
  max-width: 1100px;
  margin: 0 auto;
  .settings-sub-menu {
    height: 55px;
    width: 100%;
    max-width: 650px;
    margin: 40px auto;
    border-radius: 27.5px;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      height: 100%;
      li {
        list-style-type: none;
        justify-self: center;
        align-self: center;
        font-size: 16px;
        a.active {
          font-weight: bold;
          position: relative;
          &::after {
            content: "";
            display: block;
            width: 7px;
            height: 7px;
            background-color: #0170FD;
            border-radius: 50%;
            position: absolute;
            margin: 0 auto;
            text-align: center;
            left: 0;
            right: 0;
            bottom: -10px;
          }
        }
      }
    }
  }
}
.darkmode {
  .settings-page {
    .settings-sub-menu {
      background-color: #141619;
      box-shadow: 0 8px 24px #000000;
      a {
        color: #ffffff;
      }
    }
  }
}
.lightmode {
  .settings-page {
    .settings-sub-menu {
      background-color: white;
      box-shadow: 0 8px 24px #E5E4E6;
      a {
        color: #26242C;
      } 
    }
  }
}

@media (max-width: 768px) {
  .settings-page{
    .settings-sub-menu{
      height: auto;
      ul{
        grid-template-columns: none;
        li{
          margin: 10px 0;
        }
      }
    }
  }
}

</style>
